html {
  font-size: 60%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Helvetica;
  color: #9c9c9c;
  background: #212223;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
