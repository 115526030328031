.social-links {
  position: sticky;
  top: 40vh;
  left: 0;
  width: 8em;
  height: max-content;
  min-height: 20em;
  z-index: 5;
  background-color: #181818;
  border-radius: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: white;
  }
}

@media only screen and (max-width: 800px) {
  .social-links {
    top: 8vh;
    height: 6em;
    min-height: 5em;
    width: max-content;
    flex-direction: row;
    border-radius: 0 0 20px 20px;
    margin: auto;
  }

  html:not([data-scroll="0"]) {
    .social-links {
      top: 0;
    }
  }
}
