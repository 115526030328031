@import "animate.css";

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/helvetica-neu.ttf") format("ttf");
}

$primary-color: #181818;

input,
textarea {
  font-family: "Helvetica Neue";
}

.footer {
  width: 100%;
  text-align: center;
  padding-bottom: 5vh;

  a {
    text-decoration: none;
    color: #9c9c9c;
    font-size: 2em;
    transition: 0.3s;

    &:hover {
      color: #9c9c9c75;
    }
  }
}

.container-wrapper {
  width: 100%;
}
